.sawo-cont {
    height: 465px;
    width: 100%;
    margin: auto;
    
    /* padding-top: 5rem; */
}
.login-col
{
   
   padding: 50px 30px;
    
}
.login-col1
{
    
    padding: 20px 20px;
   
 
}
