@import url(https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.About {
    height: 100vh;
    /* background-image: url("../About/Images/waves1.png");
    background-size: 100% 100%; */

    background: linear-gradient(120deg, #FF00C7 0%, #51003F 100%), linear-gradient(120deg, #0030AD 0%, #00071A 100%), linear-gradient(180deg, #000346 0%, #FF0000 100%), linear-gradient(60deg, #0029FF 0%, #AA0014 100%), radial-gradient(100% 165% at 100% 100%, #FF00A8 0%, #00FF47 100%), radial-gradient(100% 150% at 0% 0%, #FFF500 0%, #51D500 100%);
background-blend-mode: overlay, color-dodge, overlay, overlay, difference, normal;
}

.header-about {
  text-align: left;
  font-family: "Space Mono", monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #203a61;
}

.head-desc {
  text-align: left;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 45px;
  width: 70%;
  font-family: "Space Mono", monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #203a61;
}

.about-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Cards */
.card {
  box-shadow: 0px 0px 10px rgba(32, 58, 97, 0.25);
  border-radius: 10px !important;

  margin: 0 2rem !important;
}

.Image {
  padding: 15% 20%;
}

.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #203a61;
  margin-bottom: 0px;

  text-align: center;
}

.card-text {
  font-size: 18px;

  text-align: center;

  margin-top: 22px !important;
}

/* Media Queries */
@media only screen and (max-width: 992px) {
  .group-card {
    display: flex !important;
    flex-flow: column wrap !important;
  }

  .card {
    margin: 2rem 0 !important;
  }

  .About {
    margin: 3rem 0;
    height: unset;
  }
}

@media only screen and (min-width: 993px) {
  .Image {
    height: 200px !important;
  }
}

@media only screen and (max-width:575px) {

    .header-about {
        text-align: left;
        font-family: 'Space Mono', monospace;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: #203A61;
    }

    .card {
        box-shadow: 0px 0px 10px rgba(32, 58, 97, 0.25);
        border-radius: 10px !important;
        margin: 2rem 1rem !important;
    }

    .head-desc {
        text-align: left;
        font-family: 'Space Mono', monospace;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        color: #203A61;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
    }
}

#features {
  /* Dark Blue Primary */
  padding: 6%;
  /* background: #203A61;
  background-image: url('../Features/waves.png');
  background-size: 100% 100%; */

  background: linear-gradient(320.54deg, #00069F 0%, #120010 72.37%), linear-gradient(58.72deg, #69D200 0%, #970091 100%), linear-gradient(121.28deg, #8CFF18 0%, #6C0075 100%), linear-gradient(121.28deg, #8000FF 0%, #000000 100%), linear-gradient(180deg, #00FF19 0%, #24FF00 0.01%, #2400FF 100%), linear-gradient(52.23deg, #0500FF 0%, #FF0000 100%), linear-gradient(121.28deg, #32003A 0%, #FF4040 100%), radial-gradient(50% 72.12% at 50% 50%, #EB00FF 0%, #110055 100%);
  background-blend-mode: screen, color-dodge, color-burn, screen, overlay, difference, color-dodge, normal;
}

.featureSpacing {
  margin-bottom: 10%;
}

.heading-one {
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 59px;
  color: #FFFFFF;
}

.body-one {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;

  color: #FFFFFF;
}

.inner-heading {
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 59px;
  color: #FFFFFF;
}

.inner-body {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.navText {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Dark Blue Primary */

  color: #203A61;
}

.login-heading {
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  display: flex;
  align-items: center;
  /* Dark Blue Primary */

  color: #203A61;
}

.loginSpacing {
  margin: auto 7% !important;
  padding: 3%;
}

.login-body {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 5%;
  /* Dark Blue Primary */

  color: #203A61;
}

.formSpace {
  margin-bottom: 5%;
}

::-webkit-input-placeholder {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #C4C4C4;
}

::-moz-placeholder {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #C4C4C4;
}

:-ms-input-placeholder {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #C4C4C4;
}

::-ms-input-placeholder {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #C4C4C4;
}

::placeholder {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #C4C4C4;
}
@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("/Gilroy-Bold"), url(/static/media/Gilroy-Bold.29e8a00a.woff) format("woff");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("/Gilroy-Regular"), url(/static/media/Gilroy-Regular.de88caa6.woff) format("woff");
}

.landing {
  font-family: "Gilroy-Bold", sans-serif !important;
  padding: 80px 0px;
}

.layer-1 {
  /* background: #c9d6ff;
  background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
  background: linear-gradient(to right, #e2e2e2, #c9d6ff); */
}

.background {
  background: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#d3cce3));
  background: linear-gradient(-180deg, #fff, #d3cce3);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.cta-btn {
  padding: 10px;
  width: 200px;
  font-size: 22px !important;
  background: -webkit-linear-gradient(45deg, rgb(0, 4, 128), rgb(170, 0, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.scrollText {
  font-size: 9rem;
  font-weight: 800;
  color: #4700ba;
  text-align: left;
  line-height: 10rem;
  letter-spacing: -5px;
}

.landingText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.animated-gradient-bg-1 {
  position: relative;
  display: block;
}

.animated-gradient-bg-1::before {
  content: "Practice.";
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.animated-gradient-bg-2 {
  position: relative;
  display: block;
}

.animated-gradient-bg-2::before {
  content: "Compete.";
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.animated-gradient-bg-3 {
  position: relative;
  display: block;
}

.animated-gradient-bg-3::before {
  content: "Qode.";
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.animated-gradient-fg-1 {
  background: -webkit-gradient(linear, left top, right top, from(#0896bd), to(#0012af));
  background: linear-gradient(90deg, #0896bd 0%, #0012af 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animated-gradient-text-fg-1 {
  -webkit-animation: smoooth1 8s infinite;
          animation: smoooth1 8s infinite;
}

.animated-gradient-fg-2 {
  background: -webkit-gradient(linear, left top, right top, from(#fc466b), to(#3f5efb));
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animated-gradient-text-fg-2 {
  -webkit-animation: smoooth2 8s infinite;
          animation: smoooth2 8s infinite;
}

.animated-gradient-fg-3 {
  background: -webkit-gradient(linear, left top, right top, from(#d53369), to(#daae51));
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animated-gradient-text-fg-3 {
  -webkit-animation: smoooth3 8s infinite;
          animation: smoooth3 8s infinite;
}

@-webkit-keyframes smoooth1 {
  0%,
  16.667%,
  100% {
    opacity: 1;
  }
  33.333%,
  83.333% {
    opacity: 0;
  }
}

@keyframes smoooth1 {
  0%,
  16.667%,
  100% {
    opacity: 1;
  }
  33.333%,
  83.333% {
    opacity: 0;
  }
}

@-webkit-keyframes smoooth2 {
  0%,
  100% {
    opacity: 0;
  }
  33.333%,
  50% {
    opacity: 1;
  }
  16.667%,
  66.667% {
    opacity: 0;
  }
}

@keyframes smoooth2 {
  0%,
  100% {
    opacity: 0;
  }
  33.333%,
  50% {
    opacity: 1;
  }
  16.667%,
  66.667% {
    opacity: 0;
  }
}

@-webkit-keyframes smoooth3 {
  0%,
  50%,
  100% {
    opacity: 0;
  }
  66.667%,
  83.333% {
    opacity: 1;
  }
}

@keyframes smoooth3 {
  0%,
  50%,
  100% {
    opacity: 0;
  }
  66.667%,
  83.333% {
    opacity: 1;
  }
}

.overhead {
  margin-top: 50rem;
  margin-bottom: 10rem;
  font-size: 7rem;
  color: #2B2B2B !important;
  /* background-color: #8a2387; */
}

.glassmorphic {
  /* background-color: #000; */
  background: #191a19;
  /* background: rgba(194, 19, 19, 0.1); */
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.parallax-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  height: 35rem;
  width: 35%;
  text-align: center;
  border-radius: 10px;
  padding: 2rem !important;
}

.parallax-card1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  height: 35rem;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  padding: 2rem !important;
}

.para-why {
  margin: 2rem 0;
  padding: 2rem 0;
}

.landingImg {
  width: 100%;
  border-radius: 1rem;
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.landingImg-1 {
  width: 100%;
  border-radius: 1rem;
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.landingImg:hover {
  -webkit-transform: rotate3d(1, 3, 1, 0deg) scale(1.1);
          transform: rotate3d(1, 3, 1, 0deg) scale(1.1);
}

.landingImg-1:hover {
  -webkit-transform: rotate3d(1, 3, 1, 0deg) scale(1.1);
          transform: rotate3d(1, 3, 1, 0deg) scale(1.1);
}

.parallax {
  margin-right: 10%;
}

.sticky {
  margin-left: 10%;
  background: #8a2387;
  background: -webkit-gradient(linear, left top, right top, from(#f27121), color-stop(#e94057), to(#8a2387));
  background: linear-gradient(to right, #f27121, #e94057, #8a2387);
  /* filter: blur(0.8px); */
}

.blue {
  background-image: url(/static/media/133.b3d66516.svg);
  background-color: #fff9b68e;
  background-blend-mode: screen;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.purple {
  background-image: url(/static/media/11.3a5cf3e9.svg);
  background-color: #bee9e1e5;
  background-blend-mode: screen;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.orange {
  background-image: url(/static/media/21.60cf3bb7.svg);
  background-blend-mode: screen;
  background-color: #acb6e5b2;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.lastpage {
  background-color: #acb6e5b2;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.card-text-head {
  font-size: 6rem;
}

.card-text-head-2 {
  font-size: 5rem;
}

.card-text {
  font-size: 3rem;
}

.card-text-2 {
  font-size: 1.3rem;
}

.about-feature-head {
  font-size: 4rem;
  color: #fff;
}

.about-feature-body {
  font-size: 1.5rem;
  color: #525252;
}

.align-right {
  text-align: right;
}

@media only screen and (min-width: 650px) and (max-width: 1200px) {
  .scrollText {
    font-size: 8rem;
    line-height: 8rem;
    letter-spacing: -5px;
  }

  .card-text-head {
    font-size: 4rem;
  }

  .card-text-2 {
    font-size: 1rem;
  }

  .card-text {
    font-size: 2rem;
  }

  .card-text-head-2 {
    font-size: 3rem;
  }

  .parallax-card {
    font-size: 1.5rem;
    height: 25rem;
    width: 35%;
    border-radius: 10px;
  }

  .overhead {
    font-size: 4.5rem;
    width: 100%;
    letter-spacing: -1px;
    /* background-color: red; */
    padding: 0px 30px;
    text-align: center;
  }

  .about-feature-head {
    font-size: 2.3rem;
    color: #fff;
  }

  .about-feature-body {
    font-size: 0.8rem;
    color: #525252;
  }
}

@media only screen and (max-width: 650px) {
  .qode {
    /* backdrop-filter: blur(12px); */
    /* -webkit-backdrop-filter: blur(12px); */
    background: url(/static/media/qode_purple.ddc5ea6b.svg);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    background-position: center;
    /* Center the image */
    margin: 0px;
    z-index: -1;
  }

  .background-img {
    width: 260%;
  }

  .scrollText {
    font-size: 4rem;
    line-height: 5rem;
    letter-spacing: 0px;
  }

  .card-text-head {
    font-size: 2.3rem;
  }

  .card-text-2 {
    font-size: 0.4rem;
    margin-left: 0px;
    padding-left: 0px;
  }

  .card-text {
    font-size: 0.8rem;
  }

  .card-text-head-2 {
    font-size: 2rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .parallax-card {
    font-size: 1.5rem;
    height: 10rem;
    padding: 0px;
    width: 43%;
    border-radius: 10px;
  }

  .overhead {
    font-size: 2rem;
    width: 100%;
    letter-spacing: 0px;
    padding: 0px 10px;
    text-align: center;
  }

  .about-feature-head {
    text-align: center;
    font-size: 2.3rem;
  }

  .about-feature-body {
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .landingImg {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
  }

  .landingImg-1 {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
  }

  .landingImg:hover {
    -webkit-transform: rotate3d(1, 3, 1, 0deg) scale(1.09);
            transform: rotate3d(1, 3, 1, 0deg) scale(1.09);
  }

  .landingImg-1:hover {
    -webkit-transform: rotate3d(1, 3, 1, 0deg) scale(1.09);
            transform: rotate3d(1, 3, 1, 0deg) scale(1.09);
  }

  .sticky {
    margin-left: 3%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

/* 
@media (max-width: 750px) {
  .card {
    width: 40%;
  }

  .parallax {
    margin-right: 1.5rem;
  }

  .sticky {
    margin-left: 1.5rem;
  }
} */

/* NavBar Styling Here */
.color-nav {
  height: 5rem !important;
}

.navbar-collapse {
  width: 100% !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}
.nav_logo {
  width: 8rem;
  height: 100%;
}
.navText {
  font-family: "Gilroy-Bold", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;
  /* color:white !impoWe rtant; */
}

@media (min-width: 991px) {
  .color-nav {
    background-color: transparent !important;
    height: 5rem !important;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
}
@media only screen and (max-width: 990px) {
  .nav_logo {
    width: 5rem;
  }
  .color-nav {
    background-color: transparent !important;
    height: 5rem !important;
    margin: 0px;
  }
  .navbar > .container {
    -webkit-backdrop-filter: blur(8px) !important;
    backdrop-filter: blur(8px) !important;
  }
}

.navbar-btn {
  font-size: 1rem;
  font-family: "Gilroy-Bold";
  background-color: transparent !important;
  color: #000;
  font-weight: 500;
  border-radius: 0.5rem !important;
  border: 2px solid rgb(0, 0, 123);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navbar-btn:hover {
  color: #0b5ed7;
}
.sawo-cont {
    height: 465px;
    width: 100%;
    margin: auto;
    
    /* padding-top: 5rem; */
}
.login-col
{
   
   padding: 50px 30px;
    
}
.login-col1
{
    
    padding: 20px 20px;
   
 
}

@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("../Landing//Gilroy-Bold");
}
@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("../Landing/Gilroy-Regular");
}

.tilty {
  font-family: "Gilroy-Bold", "Space Mono", monospace;
}
.getstarted-dashboard {
  background-color: #acb6e52a;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  min-height: 100vh;
  min-width: 100vw;
}

.dashboard-sheetpage {
  background-image: url(/static/media/Vector-1.3c11b807.svg);
  background: #acb6e52a;
  background: linear-gradient(-135deg, #ffffff, #acb6e5b2);

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  padding: 50px 0px;
  min-height: 100vh;
  min-width: 100vw;
}

.cols {
  /* height:100vh; */
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  position: relative;
}

.rows {
  padding-top: 90px;
}
.rows1 {
  padding-top: 100px;
  border: 0.5px solid #f2f2f2;
  border-right: none;
  padding: 30px;
  height: 500px;
}
.rows2 {
  padding-top: 100px;
  height: 500px;

  border: 0.5px solid #f2f2f2;
  padding: 30px;
}
.greet {
  font-family: "Gilroy-Bold", "Space Mono", monospace;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.image {
  display: flex;
  justify-content: center;
  height: 240px;
}
.des-p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  font-family: "Gilroy-Bold", "Space Mono", monospace;
}
.des-text {
  text-align: center;
  padding: 30px 0px;
  font-size: 20px;
  height: 100px;
}

.custom-shape-divider-bottom-1637685451 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.custom-shape-divider-bottom-1637685451 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1637685451 .shape-fill {
  fill: #b8cffa;
}

.Toastify__toast-body {
  color: white;
  font-family: "Ubuntu Mono", monospace;
  font-weight: 600;
  padding: 0 0.5rem;
}

.Toastify__toast-body .toast-subtitle {
  font-weight: 400;
  font-size: small;
}

/* Marked Complete */
.Toastify__toast.toast-Done {
  background: #56ab2f;
  background: -webkit-gradient(linear, left top, right top, from(#a8e063), to(#56ab2f));
  background: linear-gradient(to right, #a8e063, #56ab2f);
}

.Toastify__toast.toast-Done .Toastify__progress-bar {
  background: #fcfcfc96;
}

/* Marked Incomplete */
.Toastify__toast.toast-Incomplete {
  background: #ff416c;
  background: -webkit-gradient(linear, left top, right top, from(#ff4b2b), to(#ff416c));
  background: linear-gradient(to right, #ff4b2b, #ff416c);
}

.Toastify__toast.toast-Incomplete .Toastify__progress-bar {
  background: #fcfcfc96;
}

/* Notes section */

.question-title {
  width: 100%;
  background: rgb(243, 243, 243);
  margin-bottom: 0.5em;
  font-weight: bold;
  resize: none;
  font-size: 1.2em;
  border: 0.5px solid black;
  border-radius: 5px;
  padding: 0.5em 0.8em;
  word-wrap: break-word;
}

.button-container {
  display: flex;
  align-items: right;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.question-link:hover {
  color: rgb(0, 115, 192);
}
.header-rand {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.random {
  margin-bottom: 25px;
}
.random > a {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #27ae60;
  color: white;
  text-decoration: none;
}
.random > a:hover {
  color: white;
  background-color: #1a8647;
}

.table > tbody {
  text-align: center !important;
  background: rgba(255, 255, 255, 0.568);
}
td > a {
  text-decoration: none;
  font-size: 14px;
  padding: 25px 0px !important;
}
.table > thead {
  background-color: #eeeeee;
  color: #393e46;
  font-size: 13px !important;
}

/* me */
.topic-name {
  font-weight: 700;
  font-size: 3rem;
}

.question-landing {
  background: #ece9e6;
  background: -webkit-gradient(linear, left top, right top, from(#ffffff), to(#ece9e6));
  background: linear-gradient(to right, #ffffff, #ece9e6);

  background-image: url(/static/media/Vector-1.3c11b807.svg);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  /* background: #74ebd5;
  background: -webkit-linear-gradient(to right, #acb6e5, #74ebd5);
  background: linear-gradient(to right, #acb6e5, #74ebd5);
  min-height: 100vh; */
}

.container-custom {
  overflow-y: scroll;
  height: 64vh;
}

.container::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

.container::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  /* color of the tracking area */
}

.container::-webkit-scrollbar-thumb {
  background-color: rgba(54, 54, 54, 0.466);
  /* color of the scroll thumb */
  border-radius: 10px;
  /* roundness of the scroll thumb */
  border: 3px solid rgba(78, 78, 78, 0.452);
  /* creates padding around scroll thumb */
}

.inprogress-card {
  color: #fff;
  background: #56ab2f;
  background: linear-gradient(135deg, #a8e063, #56ab2f);

  border: none;
  border-radius: 15px;
  height: 90%;
  padding-bottom: 20px;
  font-family: sans-serif;
}

.topicName {
  color: #000;
  font-size: 20px;
  font-weight: 800;
}

.totalQuestion {
  /* color: #4f4f4f; */
  color: rgba(255, 255, 255, 0.829);
  font-weight: 700;
  font-size: 14px;
}

.notstarted-card {
  /* background: #ff416c;
  background: -webkit-linear-gradient(135deg, #ff4b2bd2, #ff416d77);
  background: linear-gradient(135deg, #ff4b2bd2, #ff416d77); */
  background: #56ccf2;
  background: linear-gradient(135deg, #2f80ed, #56ccf2);
  font-family: sans-serif;
  border: none;
  border-radius: 15px;
  height: 90%;
  padding-bottom: 20px;
}

.percentDone {
  color: #4f4f4f;
  font-size: 12px;
}

.container-custom2 {
  width: 40vw;
  min-width: 290px !important;
}

.hvr-grow {
  vertical-align: middle;
  -webkit-transform: perspective(0.5px) translateZ(0);
  transform: perspective(0.5px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

/* ----------Me--------------- */
.dashboard-container {
  display: flex;
  flex-direction: row;
  position: relative;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  /* background-color: #d3cce3; */
}

.header-background {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  padding: 40px 120px;
  height: 60vh;
  top: -150px;
  width: 100%;
  background-color: #fff !important;
  background: linear-gradient(-45deg,
      #acb6e5,
      #acb6e5,
      #74ebd577,
      #74ebd5a6,
      #acb6e5,
      #acb6e55d);
  background-size: 400% 400%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
  -webkit-transform: skewY(4deg);
  transform: skewY(4deg);
}

#style-1::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.404);
}

.not-started {
  text-align: center;
  color: rgba(255, 255, 255, 0.644);
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.header-title {
  text-align: right;
  font-weight: 800px;
}

.header-title>h1 {
  font-weight: 900 !important;
  font-size: 5rem;
  color: #fff;
}

.progress-update {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -150px;
  /* padding-bottom: 60px; */
  /* position: absolute; */
}

.boxes-1 {
  width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 30px !important;
  padding: 15px 0px;
  border: none;
  border-radius: 40px !important;
  background: #e0e5ec;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxes-2 {
  border: none;
  border-radius: 40px !important;
  background: #e0e5ec;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  width: 300px;
  height: 350px;
  margin-bottom: 30px !important;
  font-weight: 600;
  border-radius: 40px !important;
}

.boxes-3 {
  border-radius: 40px !important;
  background: #e0e5ec;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  border: none;
  width: 300px;
  border-radius: 40px !important;
  padding: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px !important;
}

.ques-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle-img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.hero {
  padding-bottom: 50px;
}

.card-col {
  margin: 40px 0px;
  height: 110vh;
  overflow: scroll;
  padding: 40px 0px;
  position: relative;
  top: -120px;
  border-radius: 15px;
  overflow-x: hidden;
}

.white-btn {
  font-size: 1rem;
  background-color: transparent !important;
  color: #000;
  font-weight: 500;
  border-radius: 0.5rem !important;
  border: 2px solid rgb(0, 0, 123);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.white-btn:hover {
  border-radius: 2rem !important;
  color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-height: 650px) {
  .container-custom {
    height: 56vh;
  }

  .header-title>h1 {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 650px) {
  #style-1 {
    background-color: #d7e5f7;
  }

  .header-background {
    padding-top: 140px;
    padding-left: 0px;
    padding-right: 0px;
    height: 55vh;
  }

  .header-title>h1 {
    font-size: 4rem !important;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
.editor-row {
  padding: 0 !important;
}

.input {
  border: 2px #203a61 solid;
  border-radius: 10px;
  width: 95%;
  margin: 1rem 0;
}

.output {
  border: 2px #203a61 solid;
  border-radius: 10px;
  width: 95%;
  margin: 1rem 0;
}

.input-field {
  background-color: #1e1e1e;
  width: 100%;
  height: 260px;
  padding: 1rem !important;
  color: white;
  font-family: Space Mono;
  border-radius: 1rem;
  border: 0px #203a61 solid;
  resize: none;
}

.output-field {
  background-color: #1e1e1e;
  width: 100%;
  height: 240px;
  padding: 1rem !important;
  /* color: #6aff00; */
  font-family: Space Mono;
  font-size: 1.25rem;
  border-radius: 1rem;
  border: 0px #203a61 solid;
  overflow-x:visible;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
}

.input-label {
  color: white;
  font-size: 1.25rem;
  font-family: Space Mono;
  margin: 1rem;
}

.ide {
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #1e1e1e;
  height: 100vh;
  /* margin-top: 5rem; */
}

.ide-toolbar {
  height: 75px;
  /* border: 2px #203a61 solid; */
  border-radius: 10px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
}

.qodeide-logo {
  align-items: center;
  justify-content: center;
  margin: 12px 20px;
}

.selection-console {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  align-items: center;
}

.console-btn {
  margin: 0.5rem;
}

.lang-selector {
  font-family: Space Mono;
  font-weight: 800;
}

.lang-selector-btn {
  font-size: 1rem !important;
  width: 180px !important;
}

.lang-selector-menu{
  width: 180px !important;
  /* font-size: 1.25rem !important; */

}

.run-btn {
  border: 2px solid #0078d4;
  color: #0078d4;
  font-family: Space Mono;
  width: 150px;
}

.play-btn {
  height: 20px !important;
  width: 20px !important;
}

.running {
  border: 2px solid green !important;
  background-color: green !important;
  color: white !important;
}

.running:hover {
  color: white !important;
  border: 2px solid #3cc43c !important;
  background-color: #3cc43c !important;
}
