.editor-row {
  padding: 0 !important;
}

.input {
  border: 2px #203a61 solid;
  border-radius: 10px;
  width: 95%;
  margin: 1rem 0;
}

.output {
  border: 2px #203a61 solid;
  border-radius: 10px;
  width: 95%;
  margin: 1rem 0;
}

.input-field {
  background-color: #1e1e1e;
  width: 100%;
  height: 260px;
  padding: 1rem !important;
  color: white;
  font-family: Space Mono;
  border-radius: 1rem;
  border: 0px #203a61 solid;
  resize: none;
}

.output-field {
  background-color: #1e1e1e;
  width: 100%;
  height: 240px;
  padding: 1rem !important;
  /* color: #6aff00; */
  font-family: Space Mono;
  font-size: 1.25rem;
  border-radius: 1rem;
  border: 0px #203a61 solid;
  overflow-x:visible;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
}

.input-label {
  color: white;
  font-size: 1.25rem;
  font-family: Space Mono;
  margin: 1rem;
}

.ide {
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #1e1e1e;
  height: 100vh;
  /* margin-top: 5rem; */
}

.ide-toolbar {
  height: 75px;
  /* border: 2px #203a61 solid; */
  border-radius: 10px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
}

.qodeide-logo {
  align-items: center;
  justify-content: center;
  margin: 12px 20px;
}

.selection-console {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  align-items: center;
}

.console-btn {
  margin: 0.5rem;
}

.lang-selector {
  font-family: Space Mono;
  font-weight: 800;
}

.lang-selector-btn {
  font-size: 1rem !important;
  width: 180px !important;
}

.lang-selector-menu{
  width: 180px !important;
  /* font-size: 1.25rem !important; */

}

.run-btn {
  border: 2px solid #0078d4;
  color: #0078d4;
  font-family: Space Mono;
  width: 150px;
}

.play-btn {
  height: 20px !important;
  width: 20px !important;
}

.running {
  border: 2px solid green !important;
  background-color: green !important;
  color: white !important;
}

.running:hover {
  color: white !important;
  border: 2px solid #3cc43c !important;
  background-color: #3cc43c !important;
}