@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#features {
  /* Dark Blue Primary */
  padding: 6%;
  /* background: #203A61;
  background-image: url('../Features/waves.png');
  background-size: 100% 100%; */

  background: linear-gradient(320.54deg, #00069F 0%, #120010 72.37%), linear-gradient(58.72deg, #69D200 0%, #970091 100%), linear-gradient(121.28deg, #8CFF18 0%, #6C0075 100%), linear-gradient(121.28deg, #8000FF 0%, #000000 100%), linear-gradient(180deg, #00FF19 0%, #24FF00 0.01%, #2400FF 100%), linear-gradient(52.23deg, #0500FF 0%, #FF0000 100%), linear-gradient(121.28deg, #32003A 0%, #FF4040 100%), radial-gradient(50% 72.12% at 50% 50%, #EB00FF 0%, #110055 100%);
  background-blend-mode: screen, color-dodge, color-burn, screen, overlay, difference, color-dodge, normal;
}

.featureSpacing {
  margin-bottom: 10%;
}

.heading-one {
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 59px;
  color: #FFFFFF;
}

.body-one {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;

  color: #FFFFFF;
}

.inner-heading {
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 59px;
  color: #FFFFFF;
}

.inner-body {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.navText {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Dark Blue Primary */

  color: #203A61;
}

.login-heading {
  font-family: Space Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  display: flex;
  align-items: center;
  /* Dark Blue Primary */

  color: #203A61;
}

.loginSpacing {
  margin: auto 7% !important;
  padding: 3%;
}

.login-body {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 5%;
  /* Dark Blue Primary */

  color: #203A61;
}

.formSpace {
  margin-bottom: 5%;
}

::placeholder {
  font-family: Space Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #C4C4C4;
}